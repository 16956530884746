import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/:pathMatch(.*)*",
        name: "main",
        component: () => import(/* webpackChunkName: "main" */ "../views/main-view.vue")
    },
    {
        path: "/affiliate-link/:linkid",
        name: "affiliate-link",
        component: () => import(/* webpackChunkName: "affiliate-link" */ "../views/main-new-view.vue")
    },
    {
        path: "/affiliate-link-iframe/:linkid",
        name: "affiliate-link-iframe",
        component: () => import(/* webpackChunkName: "affiliate-link-iframe" */ "../views/main-new-iframe-view.vue")
    },
    {
        path: "/registration-finished",
        name: "registration-finished",
        component: () => import(/* webpackChunkName: "registration-finished" */ "../views/registration-finished-view.vue")
    },
    {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "error" */ "../views/error-view.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
