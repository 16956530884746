import { firestore } from "@/firebase"
import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, WriteBatch } from "firebase/firestore"

export abstract class FirestoreClass {
    protected _id: string
    protected constructor(id: string) {
        this._id = id
    }

    async save() {
        if (this._id) {
            await updateDoc(this.ref, this.toData())
        } else {
            const addedDoc = await addDoc(this.collectionRef, this.toData())
            this._id = addedDoc.id
        }
    }
    async delete() {
        if (this._id) {
            await deleteDoc(this.ref)
        }
    }
    async load() {
        const doc = await getDoc(this.ref)
        if (!doc.exists()) {
            return false
        }
        this.fromData(doc.data()!)
        return true
    }
    batchSave(batch: WriteBatch) {
        if (this._id) {
            batch.update(this.ref, this.toData())
        } else {
            batch.set(doc(this.collectionRef), this.toData())
        }
    }
    fromData(data: Record<string, unknown>): void {
        throw new Error("not_implemented")
        console.log(data)
    }

    toData() {
        throw new Error("not_implemented")
        return {}
    }

    get id() {
        return this._id
    }
    get ref() {
        throw new Error("not_implemented")
        return doc(collection(firestore, ""))
    }
    get collectionRef() {
        throw new Error("not_implemented")
        return collection(firestore, "")
    }
}
